import kiwi from '../images/labels/kiwi.png';
import salad from '../images/labels/salad.png';
import arya from '../images/labels/arya.png';
import eupe from '../images/labels/eupe.png';
import lifted from '../images/labels/lifted.png';
import kyoto from '../images/labels/kyoto.png';
import wolf from '../images/labels/wolf.png';
import cudd from '../images/labels/cudd.png';
import purity from '../images/labels/purity.png';
import clov from '../images/labels/clov.png';
import apa from '../images/labels/apa.png';
import vv from '../images/labels/vv.png';
import chil from '../images/labels/chil.png';
import rac from '../images/labels/rac.png';

export const labels = [
    [
        {
            logo: kiwi,
            name: 'Kiwi Bear Records',
            description: 'Kiwi Bear Records is a husband and wife ran independent record label with the ethos of putting the artist first. We aim to provide full one-on-one support with all of our artists and their releases, laying out everything they may need to make their release a success.',
            url: 'https://open.spotify.com/user/tpkouenx3dustnxqzpkavmzna'
        },
        {
            logo: salad,
            name: 'Salad Days',
            description: 'Salad Days is a lofi label born in May 2021 as the result of the experience we gained as music publishers at our company Bonsai Publishing. We offer a range of carefully selected hip hop lo-fi instrumental beats  that are perfect for studying, relaxing, sipping a cozy morning coffee or just taking a stroll.',
            url: 'https://open.spotify.com/user/s244lrull5w747un0wiajpfbo?si=fd6e2c6537494ec3&nd=1'
        },
        {
            logo: arya,
            name: 'Arya',
            description: '',
            url: 'https://open.spotify.com/artist/1m0tdSviN7MEKE4ito6M0L'
        },
        {
            logo: eupe,
            name: 'l\'eupe',
            description: '',
            url: 'https://open.spotify.com/artist/67AA1XRZU4GSLZQe83BVk9'
        },
        {
            logo: lifted,
            name: 'Lifted LoFi Records',
            description: 'Lifted LoFi Records is an independent LoFi Hip-Hop label Based in Chicago Created in 2019 By LoFi DREL. We work with different artists from all over the world. We aim to uplift and support our artists to the fullest, so that they reach their full potential.',
            url: 'https://open.spotify.com/artist/0rgw0PdrFhmiayr1srwKkH'
        }
    ],
    [
        {
            logo: kyoto,
            name: 'Kyoto Sound',
            description: 'Kyoto Sound is an independent artist collective with an initiative to evolve the sound and put the power back in the hands of the artist.',
            url: 'https://open.spotify.com/user/y3f4924wvc68npjmq18ctfidz'
        },
        {
            logo: wolf,
            name: 'WOOLFSON',
            description: 'Traversing the sounds of lo-fi, jazz and hip-hop, London based WOOLFSON (aka Fin Woolfson) has made a fast ascent within the UK scene since his debut release in March 2021. WOOLFSON has since taken on a vast array of musical projects that have kept his repertoire both consistently diverse and colorful.',
            url: 'https://open.spotify.com/artist/3tsW6A76gKQtr7ktu5v50d'
        },
        {
            logo: cudd,
            name: 'Cuddly Weeks',
            description: 'Cuddly Weeks is a lo-fi label created by Ahry, Illuwn and Dagamon in 2021. It was initially positioned as a small collective for artists friends who help each other and simply communicate. Having found a few people, the team released their first compilation "Spring Memories", after which it was transformed into a full-fledged label that distributes and promotes lo-fi hip hop music worldwide.',
            url: 'https://open.spotify.com/playlist/2ocDn7EflCCvX0mUPYf3O5?si=4eceaf3d248046d5&nd=1'
        },
        {
            logo: purity,
            name: 'Purity',
            description: 'Purity was started in 2016 as an electronic music platform on SoundCloud. A natural transition to lofi hip hop began in 2020 with the release of their first of many instrumental compilations. Their motto is and always has been “artists helping artists” by breaking the conventions of traditional labels with a casual approach that gives power back to the artists.',
            url: 'https://open.spotify.com/user/22p3x4yhr6epnrp7aqn2mxlji?si=14f266d474184fcc&nd=1'
        },
        {
            logo: clov,
            name: 'CLOVR Records',
            description: 'CLOVR Records is a lofi/chill beats label connecting gaming with music. With all releases being DMCA & Copyright free its the place to go for finding music for content creators and businesses.',
            url: 'https://open.spotify.com/playlist/04EIjwosKPxPsgxTMAb5Yh?si=86d40806ef4c4960'
        }
    ],
    [
        {
            logo: apa,
            name: 'Studio Apa',
            description: 'Brought to you by Steve Nguyen, Studio APA (Art + Powers + Awareness) is a creative collective dedicated to exploring art beyond our reality and imagination. Collaboration and community have been the foundation of our work. Our philosophy extends to several outlets including music, publishing and visual media.',
            url: 'https://open.spotify.com/artist/0uWdW9U87jBwkzaz0Ts8p6'
        },
        {
            logo: vv,
            name: 'Vibe Valley',
            description: 'Vibe Valley is a collective of 2 swedish producers with a background in genres like musical theatre and EDM. While still having an interest in other genres, we find that Lofi is the perfect genre for creating emotion and making the listener "escape reality" for a while.',
            url: 'https://open.spotify.com/artist/5oRQXpBWZOtZOZB7lv8zMP'
        },
        {
            logo: chil,
            name: 'Chill Space',
            description: '"Chill Space" is an indie record label focused on the lo-fi genre, with most of our releases leaning on the sleepy and ambient side. The project started in early July 2021 gaining a decent amount of traction in a really short time, with over 1.000.000 streams combined on multiple platforms.',
            url: 'https://open.spotify.com/playlist/3jIZbawQyv2fVZwYn71GKL?si=430401d0463b40a9&nd=1'
        },
        {
            logo: rac,
            name: 'Raccoon',
            description: '',
            url: 'https://open.spotify.com/artist/3cYzpmIsbtgqFqBll2SA5P?si=OyaBwLJ6QRCjLA-HEeJRQw&nd=1'
        }
    ]
];