import React from 'react';
import { SecondaryAnchorButton } from '../../shared/StyledButtons/styled';

export default function Hero() {
    return (
        <div className='flex flex-column align-items-center mb-8'>
            <h2 className='text-center text-4xl md:text-5xl font-bold mb-8'>
                <span style={{ display: 'block' }}>Our partner labels and</span>
                <span style={{ display: 'block' }}>artists</span>
            </h2>
            <SecondaryAnchorButton href='https://docs.google.com/forms/d/e/1FAIpQLSe9b5QI8zHE6ufCTI735ZSncZ55LE0mWxAhBl2FIiVgOALLYA/viewform' target='_blank' rel='noreferrer'>Partner Application</SecondaryAnchorButton>
        </div>
    );
}