import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SpotifyIcon from '../../../assets/spotify-icon.svg';
import PlusIcon from '../../../assets/plus-icon.svg';
import { UnstyledButton } from '../../shared/UnstyledLink/styled';
import { StyledSpotifyButton } from './styled';

export default function Records() {
    // const [isOpen, setIsOpen] = useState(false);
    // const mockReleases = [0, 1, 2];

    return (
        <section className='flex flex-column align-items-center mb-8 w-9'>
            <div className='grid align-items-center mb-6'>
                <div className='text-center md:text-left col-12 lg:col-6 flex justify-content-center'>
                    <div className='flex flex-column text-center lg:text-left align-items-center lg:align-items-start w-25rem'>
                        <h2 className='text-4xl md:text-5xl font-bold mb-5'>
                            Slowdown Records
                        </h2>
                        <p style={{ color: '#837E7E' }} className='mb-4 font-bold'>
                            Slowdown Records is lofi.co’s label, focusing on music crafted to give you a way of
                            slowing down and take things easy, even while studying or working.
                        </p>
                        <StaticImage className='lg:hidden mb-5' quality='100' src='../../../images/slowdown-records.png' alt='album' />
                        <div className='mb-4'>
                            <StyledSpotifyButton
                                href='https://open.spotify.com/user/few9ws149dj8hel0giem7abc8'
                                target='_blank'
                                rel='noreferrer'>
                                <SpotifyIcon className='mr-2' /> Find us on Spotify
                            </StyledSpotifyButton>
                        </div>
                        {/*<UnstyledButton onClick={() => setIsOpen(!isOpen)} className='flex align-items-end font-bold lg:ml-2'><PlusIcon className='mr-2' /> See our latest release</UnstyledButton>*/}
                    </div>
                </div>
                <StaticImage className='col-12 lg:col-6 hidden lg:block' quality={100} src='../../../images/slowdown-records.png' alt='album' />
            </div>
            {/*{isOpen && (*/}
            {/*    <div className='flex flex-column lg:flex-row w-9'>*/}
            {/*        {mockReleases.map((release, index) => {*/}
            {/*            return (*/}
            {/*                <div key={index} className='flex flex-column align-items-center'>*/}
            {/*                    <StaticImage className='lg:mx-2' quality='100' src='../../../images/slowdown-records.png' alt='album' />*/}
            {/*                    <p>Release 1</p>*/}
            {/*                </div>*/}
            {/*            );*/}
            {/*        })}*/}
            {/*    </div>*/}
            {/*)}*/}
        </section>
    );
}