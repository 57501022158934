import React from 'react';
import CloseIcon from '../../../assets/close-icon.svg';
import { UnstyledButton } from '../../shared/UnstyledLink/styled';
import { StyledSpotifyButton } from '../Records/styled';
import SpotifyIcon from '../../../assets/spotify-icon.svg';

export default function Label({ label, setIsVisible }) {

    return (
        <div style={{
            zIndex: 10,
            position: 'absolute',
            left: 0,
            right: 0,
            margin: '0 auto',
            background: '#fff',
            width: '60rem',
            maxWidth: '90vw',
            boxShadow: '0 9px 20px rgba(0, 0, 0, 0.1)',
            borderRadius: '11px',
            padding: '16px 24px',
            height: 'fit-content'
        }}>
            <div className='flex justify-content-end'>
                <UnstyledButton onClick={() => setIsVisible(false)}><CloseIcon /></UnstyledButton>
            </div>
            <div className='grid px-4 md:px-8 align-items-center mb-8'>
                <div className='col-12 md:col-6'>
                    <p className='text-3xl md:text-4xl font-bold mb-4'>{label.name}</p>
                    <p style={{ color: 'rgba(131, 126, 126, 1)' }} className='font-bold mb-4'>{label.description}</p>
                    <div className='hidden md:block w-fit'>
                        <StyledSpotifyButton
                            href={label.url}
                            target='_blank'
                            rel='noreferrer'>
                            <SpotifyIcon className='mr-2' /> Find us on Spotify
                        </StyledSpotifyButton>
                    </div>
                </div>
                <div className='col-12 md:col-6 flex justify-content-center mb-4 md:mb-0'>
                    <img style={{ maxHeight: '50vh', width: '100%', objectFit: 'scale-down' }} src={label.logo} alt={label.name} />
                </div>
                <div className='col-12 md:hidden'>
                    <StyledSpotifyButton
                        href={label.url}
                        target='_blank'
                        rel='noreferrer'>
                        <SpotifyIcon className='mr-2' /> Find us on Spotify
                    </StyledSpotifyButton>
                </div>
            </div>
        </div>
    );
}