import styled from 'styled-components';

export const StyledSpotifyButton = styled.a`
  color: #fff;
  text-decoration: inherit;
  border-radius: 26px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  padding: 12px 18px;
  font: inherit;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3D8F4F;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
  `;