import React, { useState } from 'react';
import { labels } from '../../../common/labels';
import { LabelButton } from './styled';
import Label from '../Label';

export default function Labels() {
    const [isVisible, setIsVisible] = useState(false);
    const [label, setLabel] = useState({});

    const handleOpen = (label) => {
        setLabel(label);
        setIsVisible(!isVisible);
    };

    return (
        <section className='mb-8'>
            {isVisible && <Label setIsVisible={setIsVisible} label={label} />}
            <div className='grid justify-content-between mb-4'>
                {labels[0].map((label, index) => {
                    return (
                        <div key={index} className='col-2 flex justify-content-center'>
                            <LabelButton onClick={() => handleOpen(label)} key={index} className='h-8rem'>
                                <img src={label.logo} alt={label.name} />
                            </LabelButton>
                        </div>
                    );
                })}
            </div>
            <div className='grid justify-content-between mb-4'>
                {labels[1].map((label, index) => {
                    return (
                        <div key={index} className='col-2 flex justify-content-center'>
                            <LabelButton onClick={() => handleOpen(label)} key={index} className='h-8rem'>
                                <img src={label.logo} alt={label.name} />
                            </LabelButton>
                        </div>
                    );
                })}
            </div>
            <div className='grid justify-content-between mb-4'>
                <div className='col-1' />
                {labels[2].map((label, index) => {
                    return (
                        <div key={index} className='col-2 flex justify-content-center'>
                            <LabelButton onClick={() => handleOpen(label)} key={index} className='h-8rem'>
                                <img src={label.logo} alt={label.name} />
                            </LabelButton>
                        </div>
                    );
                })}
                <div className='col-1' />
            </div>
        </section>
    );
}