import styled from 'styled-components';

export const LabelButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  
  img {
    object-fit: scale-down;
    height: 100%;
    width: 100%;
  }
`;