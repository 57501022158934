import React from 'react';

// styles
import '../styles/index.css';
import '/node_modules/primeflex/primeflex.css';

// components
import MainLayout from '../hoc/MainLayout';
import Hero from '../components/music/Hero';
import Labels from '../components/music/Labels';
import Records from '../components/music/Records';
import Contact from '../components/music/Contact';
import { loadCrispChat } from '../common/utils';
import Seo from '../components/shared/Seo';

const MusicPage = () => {
    loadCrispChat();

    return (
        <MainLayout>
            <Seo title='Lofi.co - Craft your focus environment' keywords={['music', 'focus', 'relax', 'work']} lang='en' />
            <main className='flex flex-column align-items-center'>
                <Hero />
                <Labels />
                <Records />
                <Contact />
            </main>
        </MainLayout>
    );
};

export default MusicPage;