import React from 'react';
import { SecondaryAnchorButton } from '../../shared/StyledButtons/styled';

export default function Contact() {
    return (
        <>
            <h2 className='text-center text-3xl md:text-4xl font-bold mb-6'>
            Want to release with us?
            </h2>
            <SecondaryAnchorButton href='https://docs.google.com/forms/d/e/1FAIpQLSfcXswBLI1poUKFoj1XW9w7yLBYVubD09P_VekUBCsxyEKPPQ/viewform'
                target='_blank'
                rel='noreferrer'
                className='mb-8'>
                Contact us
            </SecondaryAnchorButton>
        </>
    );
}